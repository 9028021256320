import { helpers } from "vuelidate/lib/validators";
import Validations from "@/models/Validations.model";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";
import {getValidationLength} from "@/misc/GetValidationLength";

// Regex for URL (https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url):
const URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const PHONE_REGEX = /^[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

// Number with dot or comma as decimal separator
export const decimalNumber = (value: string) =>  !value || /^-?\d*(\,?|\.?)\d*$/.test(value);

export const greaterThan = (min: number) => helpers.withParams(
    { type: 'greatherThan', min: min },
    (value: string) => {
        if (!value) return true;
        const numberVal: number = Number(value.toString().replace(',', '.'));
        return numberVal > min;
    }
);

// Minimum value validator that can handle decimals with comma as separator
export const minValue = (min: number) => helpers.withParams(
  { type: 'minValue', min: min },
  (value: string) => {
    if (!value) return true;
    const numberVal: number = parseFloat(value.toString().replace(',', '.'));
    return Math.floor(numberVal) >= min;
  }
);

// Maximum value validator that can handle decimals with comma as separator
export const maxValue = (max: number) => helpers.withParams(
  { type: 'maxValue', max: max },
  (value: string) => { 
    if (!value) return true;
    const numberVal: number = parseFloat(value.toString().replace(',', '.'));
    return Math.floor(numberVal) <= max;
  }
);

export const maxLength = (identifier: MaxLengthValidation) => helpers.withParams(
    { type: 'maxLength', identifier: identifier},
    (value: string) => {
        if(!value) return true;
        return value.length <= getValidationLength(identifier);
    }
);

// Url validator without required http:// oder https://
export const url = (value: string) => !value || URL_REGEX.test(value);

export const phone = (value: string) => !value || PHONE_REGEX.test(value);

export const number = (value: number) => !isNaN(value);