import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";
import Validations from "@/models/Validations.model";

export const getValidationLength = (type: MaxLengthValidation): number => {
    const result = validationTypes.get(type);
    return result ?? -1;
};

const validationTypes: Map<string, number> = new Map([
    [MaxLengthValidation.EMAIL, Validations.email],
    [MaxLengthValidation.PASSWORD, Validations.password],
    [MaxLengthValidation.FCM_TOKEN, Validations.fcmToken],
    [MaxLengthValidation.STREET, Validations.street],
    [MaxLengthValidation.HOUSE_NO, Validations.houseNo],
    [MaxLengthValidation.ZIP, Validations.zip],
    [MaxLengthValidation.CITY, Validations.city],
    [MaxLengthValidation.COUNTRY, Validations.country],
    [MaxLengthValidation.PHONE, Validations.phone],
    [MaxLengthValidation.COMPANY_NAME, Validations.companyName],
    [MaxLengthValidation.COMPANY_LEGAL_NAME, Validations.companyLegalName],
    [MaxLengthValidation.FIRST_NAME, Validations.firstName],
    [MaxLengthValidation.LAST_NAME, Validations.lastName],
    [MaxLengthValidation.CONTAINER_TYPE_NAMES, Validations.containerTypeName],
    [MaxLengthValidation.CONTAINER_TYPE_DESCRIPTION, Validations.containerTypeDescription],
    [MaxLengthValidation.CONTAINER_TYPE_INTERNAL_INSTRUCTIONS, Validations.containerTypeInternalInstruction],
    [MaxLengthValidation.NEWS_DESCRIPTION, Validations.newsDescription],
    [MaxLengthValidation.NEWS_SUB_TITLE, Validations.newsSubTitle],
    [MaxLengthValidation.SSO_IDENTIFIER, Validations.ssoIdentifier],
    [MaxLengthValidation.SSO_EMAIL, Validations.ssoEmail],
    [MaxLengthValidation.FIXED_ZIP, 5]
]);