import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * repository that deals with all the api requests that have to do with restauranteurs
 */
export default class BonificationRepository {
    private static baseUrl: string = 'api/bonification';

    /**
     * gets the user based on the token that is used
     */
    public static getBonification(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}`);
    }

    /**
     * gets the user based on the token that is used
     */
    public static updateBonification(name: string, value: number): Promise<AxiosResponse> {
        return ENTITY_CLIENT.put(`${this.baseUrl}/${name}`, {
            'earnings': value
        });
    }
}
