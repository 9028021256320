import Parseable from '@/misc/Parseable';

export default class Bonification extends Parseable {
    public earnings!: number;
    public enabled!: boolean;
    public name!: BonificationType;

    public static parseFromObject(object: any): Bonification {
        const bonification = new Bonification();
        Object.assign(bonification, object);
        return bonification;
    }

    public parseToObject(): Partial<Bonification> {
        return { ...this };
    }
}

export enum BonificationType {
    RENTAL = 'RENTAL',
    PAYMENT = 'PAYMENT'
}