
import {Component} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import BonificationRepository from "@/api/repositories/BonificationRepository";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import Bonification, {BonificationType} from "@/models/Bonification.model";
import {number} from "@/misc/CustomValidators";

@Component({
  mixins: [validationMixin],
  validations: {
    rentalEarning: {
      required,
      number
    },

    paymentEarning: {
      required,
      number
    }
  }
})
export default class BonificationView extends mixins(ErrorMessageHandlerMixin) {
  private rentalEarning: string = '';
  private paymentEarning: string = '';

  /**
   * State Bool for a Loading Indicator
   * @private
   */
  private isLoading = false;

  private async created() {
    try {
      this.isLoading = true;
      const {data} = await BonificationRepository.getBonification();
      const bonifications = data.map((b: Bonification) => Bonification.parseFromObject(b));

      this.rentalEarning = bonifications.find((b: Bonification) => b.name === BonificationType.RENTAL).earnings.toString();
      this.paymentEarning = bonifications.find((b: Bonification) => b.name === BonificationType.PAYMENT).earnings.toString();
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the user clicks on the save button, validates the inputs and tries to change the password of the
   * user
   * @private
   */
  private async saveData() {
    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      // tries to change the password in the api
      this.isLoading = true;

      await BonificationRepository.updateBonification(BonificationType.PAYMENT.toString(),
          parseFloat(this.paymentEarning));

      await BonificationRepository.updateBonification(BonificationType.RENTAL.toString(),
          parseFloat(this.rentalEarning));

      // Show Success Message
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.BONIFICATION.SUCCESS');
    } catch (_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.BONIFICATION.ERROR');
    } finally {
      this.isLoading = false;
    }
  }
}
