export enum MaxLengthValidation {
    PASSWORD = 'PASSWORD',
    EMAIL = 'EMAIL',
    FCM_TOKEN = 'FCM_TOKEN',
    STREET = 'STREET',
    HOUSE_NO = 'HOUSE_NO',
    ZIP = 'ZIP',
    CITY = 'CITY',
    COUNTRY = 'COUNTRY',
    PHONE = 'PHONE',
    COMPANY_NAME = 'COMPANY_NAME',
    COMPANY_LEGAL_NAME = 'COMPANY_LEGAL_NAME',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    CONTAINER_TYPE_NAMES = 'CONTAINER_TYPE_NAMES',
    CONTAINER_TYPE_DESCRIPTION = 'CONTAINER_TYPE_DESCRIPTION',
    CONTAINER_TYPE_INTERNAL_INSTRUCTIONS = 'CONTAINER_TYPE_INTERNAL_INSTRUCTIONS',
    NEWS_DESCRIPTION = 'NEWS_DESCRIPTION',
    NEWS_SUB_TITLE = 'NEWS_SUB_TITLE',
    SSO_IDENTIFIER = 'SSO_IDENTIFIER',
    SSO_EMAIL = 'SSO_EMAIL',
    FIXED_ZIP = 'FIXED_ZIP'
}